import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";
// import { green } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    // flex: 1,
    // padding: theme.spacing(2),
    // border: "1px solid #e0e0e0",

  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    ...theme.scrollbarStyles,
    backgroundColor: theme.palette.chatlist, //DARK MODE PLW DESIGN//
    borderRadius: "10px",
    minWidth: "100%",
  },
  inputArea: {
    position: "relative",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    padding: "20px",
  },
  buttonSend: {
    margin: theme.spacing(1),
  },
  boxLeft: {
    padding: "10px",
    margin: "10px",
    position: "relative",
    backgroundColor: "#e4e7ed",
    maxWidth: "50%",
    borderRadius: "10px",
  },
  boxRight: {
    padding: "10px",
    margin: "0 0 0 auto",
    position: "relative",
    backgroundColor: theme.palette.secondary.main, //DARK MODE PLW DESIGN//
    textAlign: "left",
    maxWidth: "50%",
    borderRadius: "10px",
  },
}));

export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();

  const [contentMessage, setContentMessage] = useState("");

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  const unreadMessages = (chat) => {
    if (chat !== undefined) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser.unreads > 0;
    }
    return 0;
  };

  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) { }
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || loading) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };

  return (
    <Paper className={classes.mainContainer}>

      <div onScroll={handleScroll} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => {
            if (item.senderId === user.id) {
              return (
                <Grid container justifyContent="flex-end">
                  <Grid xs={12} item style={{ marginBottom: "10px" }}>
                    <Box key={key} className={classes.boxRight} style={{ wordBreak: "break-all" }}>
                      <Typography variant="subtitle2">
                        {item.sender.name}
                      </Typography>
                      <Typography variant="caption">
                        {item.message}
                      </Typography>
                      <Grid item>
                        <Typography variant="caption" style={{ width: "100%", textAlign: "right", color: "white" }}>
                          {datetimeToClient(item.createdAt)}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid container justifyContent="flex-start">
                  <Grid xs={12} item style={{ marginBottom: "10px" }}>
                    <Box key={key} className={classes.boxLeft} style={{ wordBreak: "break-all" }}>
                      <Typography variant="subtitle2">
                        {item.sender.name}
                      </Typography>
                      <Typography variant="caption">
                        {item.message}
                      </Typography>
                      <Grid item>
                        <Typography variant="caption" style={{ width: "100%", textAlign: "left" }}>
                          {datetimeToClient(item.createdAt)}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              );
            }
          })}
        <div ref={baseRef}></div>
      </div>

        <FormControl variant="outlined" fullWidth>
          <Input
            // multiline
            margin="dense"
            value={contentMessage}
            onKeyUp={(e) => {
              if (e.key === "Enter" && contentMessage.trim() !== "") {
                handleSendMessage(contentMessage);
                setContentMessage("");
              }
            }}
            onChange={(e) => setContentMessage(e.target.value)}
            className={classes.input}
            

            endAdornment={
              <InputAdornment position="end">
                <Button
                  // variant="contained"
                  // color="primary"
                  // disableElevation
                  onClick={() => {
                    if (contentMessage.trim() !== "") {
                      handleSendMessage(contentMessage);
                      setContentMessage("");
                    }
                  }}
                  className={classes.buttonSend}
                >
                  <SendIcon />
                 
                  {/* Enviar mensaje */}
                </Button>
              </InputAdornment>
            }
          />
        </FormControl>
      
    </Paper>
  );
}
