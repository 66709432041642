import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import { QueueOptions } from "../QueueOptions";
import SchedulesForm from "../SchedulesForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    outOfHoursMessage: "",
    orderQueue: "",
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const greetingRef = useRef();

  const [schedules, setSchedules] = useState([
    { weekday: "Lunes", weekdayEn: "monday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Martes", weekdayEn: "tuesday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Miércoles", weekdayEn: "wednesday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Jueves", weekdayEn: "thursday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Viernes", weekdayEn: "friday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Sábado", weekdayEn: "saturday", startTime: "08:00", endTime: "12:00", },
    { weekday: "Domingo", weekdayEn: "sunday", startTime: "00:00", endTime: "00:00", },
  ]);

  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const scheduleType = data.find((d) => d.key === "scheduleType");
        if (scheduleType) {
          setSchedulesEnabled(scheduleType.value === "queue");
        }
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
        setSchedules(data.schedules);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "",
        greetingMessage: "",
        orderQueue: "",
      });
    };
  }, [queueId, open]);

  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  const handleSaveQueue = async (values) => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, { ...values, schedules });
      } else {
        await api.post("/queue", { ...values, schedules });
      }
      toast.success("Cola guardada correctamente");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleSaveSchedules = async (values) => {
    toast.success("Haga clic en Guardar para registrar los cambios");
    setSchedules(values);
    setTab(0);
  };

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </DialogTitle>

        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setTab(v)}
          aria-label="disabled tabs example"
        >
          <Tab label="Datos de la cola" />
          {schedulesEnabled && <Tab label="Horarios de apertura" />}
        </Tabs>

        {tab === 0 && (
          <Paper>
            <Formik
              initialValues={queue}
              enableReinitialize={true}
              validationSchema={QueueSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveQueue(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <Form>
                  <DialogContent dividers>

                    <Grid container spacing={2} className={classes.multFieldLine}>

                      <Grid xs={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.name")}
                          autoFocus
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          fullWidth
                          // margin="dense"
                          className={classes.textField}
                        />
                      </Grid>

                      <Grid container spacing={2} className={classes.multFieldLine}>
                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 10 }}>
                          <Field
                            as={TextField}
                            label={i18n.t("queueModal.form.color")}
                            name="color"
                            id="color"
                            fullWidth
                            onFocus={() => {
                              setColorPickerModalOpen(true);
                              greetingRef.current.focus();
                            }}
                            error={touched.color && Boolean(errors.color)}
                            helperText={touched.color && errors.color}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div
                                    style={{ backgroundColor: values.color }}
                                    className={classes.colorAdorment}
                                  ></div>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <IconButton
                                  color="default"
                                  onClick={() => setColorPickerModalOpen(true)}
                                >
                                  <Colorize />
                                </IconButton>
                              ),
                            }}
                            variant="outlined"
                            // margin="dense"
                            className={classes.textField}
                          />
                          <ColorPicker
                            open={colorPickerModalOpen}
                            handleClose={() => setColorPickerModalOpen(false)}
                            onChange={(color) => {
                              values.color = color;
                              setQueue(() => {
                                return { ...values, color };
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 10 }}>

                          <Field
                            as={TextField}
                            label={i18n.t("queueModal.form.orderQueue")}
                            name="orderQueue"
                            type="orderQueue"
                            fullWidth
                            error={touched.orderQueue && Boolean(errors.orderQueue)}
                            helperText={touched.orderQueue && errors.orderQueue}
                            variant="outlined"
                            // margin="dense"
                            className={classes.textField1}
                          />
                        </Grid>
                      </Grid>

                      <Grid xs={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.greetingMessage")}
                          type="greetingMessage"
                          multiline
                          inputRef={greetingRef}
                          rows={5}
                          fullWidth
                          name="greetingMessage"
                          error={
                            touched.greetingMessage &&
                            Boolean(errors.greetingMessage)
                          }
                          helperText={
                            touched.greetingMessage && errors.greetingMessage
                          }
                          variant="outlined"
                        // margin="dense"
                        />
                      </Grid>

                      <Grid xs={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
                        {schedulesEnabled && (
                          <Field
                            as={TextField}
                            label={i18n.t("queueModal.form.outOfHoursMessage")}
                            type="outOfHoursMessage"
                            multiline
                            rows={5}
                            fullWidth
                            name="outOfHoursMessage"
                            error={
                              touched.outOfHoursMessage &&
                              Boolean(errors.outOfHoursMessage)
                            }
                            helperText={
                              touched.outOfHoursMessage && errors.outOfHoursMessage
                            }
                            variant="outlined"
                          // margin="dense"
                          />
                        )}
                      </Grid>
                      
                    </Grid>

                    <QueueOptions queueId={queueId} />
                  </DialogContent>

                  <DialogActions>
                    <Button
                      color="default"
                      variant="contained"
                      disableElevation
                      onClick={handleClose}
                      disabled={isSubmitting}
                    >
                      {i18n.t("queueModal.buttons.cancel")}
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disableElevation
                      disabled={isSubmitting}
                      className={classes.btnWrapper}
                    >
                      {queueId
                        ? `${i18n.t("queueModal.buttons.okEdit")}`
                        : `${i18n.t("queueModal.buttons.okAdd")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Paper>
        )}
        {tab === 1 && (
          <Paper style={{ padding: 20 }}>
            <SchedulesForm
              loading={false}
              onSubmit={handleSaveSchedules}
              initialValues={schedules}
              labelSaveButton="Agregar"
            />
          </Paper>
        )}
      </Dialog>
    </div>
  );
};

export default QueueModal;
