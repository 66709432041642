import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
}));

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid }) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedQueue, setSelectedQueue] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const loadQueues = async () => {
        const list = await findAllQueues();
        setQueues(list);
      };
      loadQueues();
    }
  }, []);

  useEffect(() => {
    if (!selectedQueue) {
      setUsers([]);
      return;
    }
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/user/filter", {
          params: { queueId: selectedQueue },
        });
        setUsers(data.users);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [selectedQueue]);

  const handleClose = () => {
    onClose();
    setSelectedUser("");
    setSelectedQueue("");
  };

  const handleSaveTicket = async (e) => {
    e.preventDefault();
    if (!ticketid) return;
    if (!selectedQueue || selectedQueue === "" || !selectedUser || selectedUser === "") return;
    setLoading(true);
    try {
      let data = {
        userId: selectedUser,
        queueId: selectedQueue,
        status: "open",
      };
      console.log(data)

      await api.put(`/tickets/${ticketid}`, data);
      history.push(`/tickets`);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <form onSubmit={handleSaveTicket}>
        <DialogTitle>{i18n.t("transferTicketModal.title")}</DialogTitle>
        <DialogContent dividers>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
            <Select
              value={selectedQueue}
              onChange={(e) => setSelectedQueue(e.target.value)}
              label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
            >
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>{i18n.t("transferTicketModal.fieldLabel")}</InputLabel>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label={i18n.t("transferTicketModal.noOptions")}
              disabled={!selectedQueue || loading}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {loading && <CircularProgress size={24} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="contained">
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner type="submit" color="primary" variant="contained" loading={loading}>
            {i18n.t("transferTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferTicketModalCustom;