import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { LinearProgress, Dialog, DialogContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progressContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
  },
  progressBar: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  progressLabel: {
    minWidth: 35,
  },
}));


const LoadingOverlay = ({ open, progress }) => (
  <Dialog open={open} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
    <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LinearProgress variant="determinate" value={progress} style={{ width: '100%' }} />
      <p>{progress}%</p>
    </DialogContent>
  </Dialog>
);




export default LoadingOverlay;
