import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { Box, Grid } from '@material-ui/core';
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "auto",
  },
  mainContainer: {
    padding: theme.spacing(2),
    border: "1px solid #e0e0e0",
    width: "100%",
  },
  gridContainer: {
    flex: 1,
    padding: theme.spacing(2),
  },
  customCard: {
    '& .TWbMn': {
      width: '280px',
      maxWidth: '100%',
      minWidth: '200px',
      wordWrap: 'break-word',
    },
  },
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tags, setTags] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [file, setFile] = useState({ lanes: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useContext(AuthContext);
  const [noTagCount, setNoTagCount] = useState(0);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      setTags(fetchedTags);
      await fetchTickets(1, true); // Fetch initial tickets
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTickets = async (page, reset = false) => {
    try {
      const params = {
        queueIds: JSON.stringify(jsonString),
        pageNumber: page,
        limit: 30,
      };
      const { data } = await api.get("/ticket/kanban", { params });
      setTickets(prevTickets => reset ? data.tickets : [...prevTickets, ...data.tickets]);
      setHasMore(data.hasMore);
      setNoTagCount(data.noTagCount);  
      //console.log(data.count)
     // console.log(data.noTagCount)
      setCurrentPage(page);
    } catch (err) {
      console.error('Error en la búsqueda de entradas:', err);
      setTickets([]);
    }
  };

  useEffect(() => {
    popularCards();
  }, [tags, tickets, noTagCount]);

  const popularCards = () => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);
    const lanes = [
      {
        id: "lane0",
        title: i18n.t("Sin etiquetar"),
        label: `Total: ${noTagCount}`,
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          label: "Ticket Nº " + ticket.id.toString(),
          description: (
            <Grid className={classes.gridContainer} container>
              <Grid  xs={12} item>
                <Box style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                  {"Fecha: " + moment.utc(ticket.updatedAt).local().format('DD/MM/YYYY')}
                  {"Hora: " + moment.utc(ticket.updatedAt).local().format('HH:mm')}
                </Box>
                <Box>
                  <div style={{ width: '250px' }}>{ticket.contact.number}</div>
                  <div style={{ width: '250px' }}>{ticket.lastMessage}</div>
                </Box>
                <Button
                  fullWidth
                  color="secondary"
                  disableElevation
                  variant="contained"
                  onClick={() => handleCardClick(ticket.uuid)}
                >
                  Ver Ticket
                </Button>
              </Grid>
            </Grid>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
        })),
      },
      ...tags.map(tag => {
        const filteredTickets = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });
        return {
          id: tag.id.toString(),
          title: `${tag.name}`,
          label: `(${filteredTickets.length})`,
          cards: filteredTickets.map(ticket => ({
            id: ticket.id.toString(),
            label: `Ticket Nº ${ticket.id}`,
            description: (
              <Grid className={classes.gridContainer} container>
                <Grid xs={12} item>
                  <Box style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                    {"Fecha: " + moment(ticket.updatedAt).format('DD/MM/YYYY') + " "}
                    {" " + "Hora: " + moment(ticket.updatedAt).format('HH:mm')}
                  </Box>
                  <Box>
                    <div style={{ width: '150px' }}>{ticket.contact.number}</div>
                    <div style={{ width: '150px' }}>{ticket.lastMessage}</div>
                  </Box>
                  <Button
                    fullWidth
                    color="secondary"
                    disableElevation
                    variant="contained"
                    onClick={() => handleCardClick(ticket.uuid)}
                  >
                    Ver Ticket
                  </Button>
                </Grid>
              </Grid>
            ),
            title: ticket.contact.name,
            draggable: true,
            href: `/tickets/${ticket.uuid}`,
          })),
          style: { backgroundColor: tag.color, color: "white" }
        };
      }),
    ];
    setFile({ lanes });
  };

  const handleCardClick = (uuid) => {
    history.push('/tickets/' + uuid);
  };

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      toast.success('Ticket Tag Removido!');
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
      toast.success('¡Etiqueta de entrada añadida con éxito!');
      await fetchTickets(1, true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore) {
      fetchTickets(currentPage + 1);
    }
  };

  return (
    <div className={classes.root} onScroll={handleScroll}>
      <Board
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
        style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
      />
    </div>
  );
};

export default Kanban;
