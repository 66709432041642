import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';
import api from '../../services/api';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import { green } from "@material-ui/core/colors";
import InvoiceModal from '../../components/InvoiceModal';
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(2),
        border: '1px solid #e0e0e0',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    circleLoading: {
        color: green[500],
        position: "absolute",
        opacity: 0.7,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)" // Centra el CircularProgress
    },
}));

const Transactions = () => {
    const classes = useStyles();
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const params = { startDate, endDate, page: page + 1, rowsPerPage };
            const { data } = await api.post('/subscriptionParams', params);
            const processedTransactions = processTransactions(data);
            setTransactions(processedTransactions);
            console.log(data)
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [startDate, endDate, page, rowsPerPage]);

    const processTransactions = (data) => {
        const mainTransactions = new Map();
        data.forEach(transaction => {
            const txnInfo = transaction.transaction_info;
            if (!txnInfo.paypal_reference_id) {
                mainTransactions.set(txnInfo.transaction_id, {
                    date: moment(txnInfo.transaction_initiation_date).format('DD/MM/yyyy'),
                    description: 'Pago de',
                    name: transaction.payer_info.payer_name.alternate_full_name,
                    company: transaction.subscriptionDetails[0].companyName,
                    plan: transaction.subscriptionDetails[0].planName,
                    invoiceID: transaction.subscriptionDetails[0].invoiceID,
                    status: txnInfo.transaction_status,
                    gross: txnInfo.transaction_amount.value,
                    fee: txnInfo.fee_amount ? txnInfo.fee_amount.value : "0.00",
                    net: undefined
                });
            }
        });

        data.forEach(transaction => {
            const txnInfo = transaction.transaction_info;
            if (txnInfo.paypal_reference_id) {
                const mainTxn = mainTransactions.get(txnInfo.paypal_reference_id);
                if (mainTxn) {
                    mainTxn.net = txnInfo.transaction_amount.value;
                }
            }
        });

        return Array.from(mainTransactions.values());
    };

    const handleDownload = async (invoiceID) => {
        try {
            const response = await api.post('/downals', { invoiceID }, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            toastError('Failed to download PDF');
            console.error(err);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenInvoiceModal = (invoiceID) => {
        setSelectedInvoice({ id: invoiceID }); // Guarda los datos de la factura seleccionada
        setInvoiceModalOpen(true); // Abre el modal
    };

    const handleCloseInvoiceModal = () => {
        setSelectedInvoice(null);
        setInvoiceModalOpen(false);
    };

    return (
        <MainContainer>
            <InvoiceModal
                open={invoiceModalOpen}
                onClose={handleCloseInvoiceModal}
                invoice={selectedInvoice} // Pasa los datos de la factura si el modal los necesita
            />
            <Paper className={classes.mainPaper}>
                <MainHeader>
                    <Title>Transacciones</Title>
                    <Box style={{ padding: 10, display: 'flex', gap: 20, width: '45%' }}>
                        <TextField label="Fecha desde" type="date" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        <TextField label="Fecha hasta" type="date" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Box>
                </MainHeader>

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Empresa</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Bruto</TableCell>
                            <TableCell>Comisión</TableCell>
                            <TableCell>Neto</TableCell>
                            <TableCell>Fac.</TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <CircularProgress className={classes.circleLoading} />
                    ) : (
                        <TableBody>
                            {transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction, index) => (
                                <TableRow key={index}>
                                    <TableCell>{transaction.date}</TableCell>
                                    <TableCell>{transaction.company}</TableCell>
                                    <TableCell>{transaction.plan}</TableCell>
                                    <TableCell>{transaction.name}</TableCell>
                                    <TableCell>{transaction.status}</TableCell>
                                    <TableCell>{transaction.gross}</TableCell>
                                    <TableCell>{transaction.fee}</TableCell>
                                    <TableCell>{transaction.net}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="descargar" onClick={() => handleDownload(transaction.invoiceID)}>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 40, 60]}
                    component="div"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </MainContainer>
    );
};

export default Transactions;
