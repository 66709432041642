import React, { useState, useEffect, useReducer } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MinimizeIcon from '@material-ui/icons/Minimize';
import AddIcon from '@material-ui/icons/Add';
import usePlans from "../../../hooks/usePlans";
import useCompanies from "../../../hooks/useCompanies";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },


  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },

  customCard: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    flexDirection: "column",
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));


export default function Pricing(props) {
  const {
    setFieldValue,
    setActiveStep,
    activeStep,
  } = props;



  const { list, finder } = usePlans();
  const { find } = useCompanies();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const classes = useStyles();
  const [usersPlans, setUsersPlans] = React.useState(3);
  const [companiesPlans, setCompaniesPlans] = useState(0);
  const [connectionsPlans, setConnectionsPlans] = React.useState(3);
  const [storagePlans, setStoragePlans] = React.useState([]);
  const [customValuePlans, setCustomValuePlans] = React.useState(49.00);
  const [loading, setLoading] = React.useState(false);
  const companyId = localStorage.getItem("companyId");

  useEffect(() => {
    async function fetchInitialData() {
      setLoading(true);
      try {
        const companyDetails = await find(companyId);
        const currentPlanDetails = await finder(companyDetails.planId);
        const currentPlan = {
          title: currentPlanDetails.name,
          planId: currentPlanDetails.id,
          price: currentPlanDetails.value,
        
          description: [
            `${currentPlanDetails.users} users`,
            `${currentPlanDetails.connections} connections`,
            `${currentPlanDetails.queues} queues`
          ],
          buttonText: 'Seleccionar',
          buttonVariant: 'contained',
        };
        const allPlansData = await list(); // Supongamos que no requiere parámetros
        const allPlans = allPlansData.map(plan => ({
          title: plan.name,
          planId: plan.id,
          price: plan.value,
          description: [
            `${plan.users} users`,
            `${plan.connections} connections`,
            `${plan.queues} queues`
          ],
          buttonText: 'Seleccionar',
          buttonVariant: 'contained',
        }));

        setPlans([currentPlan, ...allPlans.filter(plan => plan.planId !== currentPlan.planId)]);
        setSelectedPlan(currentPlan); // Establece el plan actual como seleccionado inicialmente
      } catch (error) {
        console.error("Error loading plans:", error);
      }
      setLoading(false);
    }
    fetchInitialData();
  }, [companyId]);

  return (
    <React.Fragment>
      <Box style={{ padding: 10, width: "100%" }}>
        <Autocomplete
          options={plans}
          value={selectedPlan}
          onChange={(event, newValue) => setSelectedPlan(newValue)}
          getOptionLabel={(option) => `${option.title} - $${option.price}`}
          renderInput={(params) => (
            <TextField {...params} label="Select Plan" variant="outlined" fullWidth />
          )}
        />
      </Box>
      <Grid container spacing={3}>
        {selectedPlan ? (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={selectedPlan.title}
                subheader={selectedPlan.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={selectedPlan.title === 'Pro' ? <StarIcon /> : null}
                className={classes.cardHeader}
              />

              <CardContent>
              <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                  ${selectedPlan.price}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /mes
                  </Typography>
                </div>
               
                <ul>
                  {selectedPlan.description.map((line, index) => (
                    <Typography component="li" key={index} align="center">{line}</Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={plans.buttonVariant}
                  color="primary"
                  disableElevation
                  onClick={() => {
                    if (selectedPlan.custom) {
                      setFieldValue("plan", JSON.stringify({
                        users: usersPlans,
                        connections: connectionsPlans,
                        price: customValuePlans,
                      }));
                    } else {
                      setFieldValue("plan", JSON.stringify(selectedPlan));
                    }
                    setActiveStep(activeStep + 1);
                  }}


                >
                  Próximo
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
