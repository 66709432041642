import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green, grey, red, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import RoomIcon from '@material-ui/icons/Room';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import DoneIcon from '@material-ui/icons/Done';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import contrastColor from "../../helpers/contrastColor";
import ContactTag from "../ContactTag";
import { Palette } from "@material-ui/icons";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  newMessagesCount: {
    position: "absolute",
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
    top: "10px",
    left: "20px",
    borderRadius: 3,
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  connectionTag: {
    background: "rgb(67, 83, 105)",
    color: "white",
    paddingVertical: 4,
    fontWeight: 'bold',
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "5px",
  },

  lastMessageTime: {
    // justifySelf: "flex-start",
    // textAlign: "left",
    // position: "relative",
    top: 0,
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 0,
    marginLeft: "5px",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    gap: 2,
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: -13
  },

  secondaryContentSecond: {
    fontSize: 12,
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "5px",
  },

  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0
  },

  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3
    },

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  }
}));
{/*PLW DESIGN INSERIDO O dentro do const handleChangeTab*/ }
const TicketListItemCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);

  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name?.toUpperCase());
    }
    setTicketQueueName(ticket.queue?.name?.toUpperCase());
    setTicketQueueColor(ticket.queue?.color);

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name?.toUpperCase());
    }

    setTag(ticket?.tags);

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  {/*CÓDIGO NOVO SAUDAÇÃO*/ }
  const handleCloseTicket = async (id) => {
    setTag(ticket?.tags);
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
  };

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });

      let settingIndex;

      try {
        const { data } = await api.get("/settings/");

        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");

      } catch (err) {
        toastError(err);

      }

      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        
        handleSendMessage(ticket.id);

      }

    } catch (err) {
      setLoading(false);

      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    // handleChangeTab(null, "tickets");
    // handleChangeTab(null, "open");
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSendMessage = async (id) => {

    const msg = `{{ms}} *{{name}}*, mi nombre es *${user?.name}* ¡y ahora voy a continuar con su servicio!`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `\n${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);

    }
  };
  {/*CÓDIGO NOVO SAUDAÇÃO*/ }

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const renderTicketInfo = () => {
    if (ticketUser) {

      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}

          {/* </span> */}
        </>
      );
    } else {
      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}

        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      ></TicketMessagesDialog>
      <ListItem dense button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >

        <Tooltip arrow placement="right" title={ticket.queue?.name?.toUpperCase() || "SIN ARCHIVOS"} >
          <span style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }} className={classes.ticketQueueColor}></span>
        </Tooltip>

        <ListItemAvatar>
          {ticket.status !== "pending" ?
            <Avatar
              style={{
                // marginTop: "-20px",
                marginLeft: "-3px",
                width: "55px",
                height: "55px",
                borderRadius: "100%",
              }}
              src={ticket?.contact?.profilePicUrl}
            />
            :
            <Avatar
              style={{
                // marginTop: "-30px",
                marginLeft: "0px",
                width: "50px",
                height: "50px",
                borderRadius: "100%",
              }}
              src={ticket?.contact?.profilePicUrl}
            />
          }
        </ListItemAvatar>
        <ListItemText
          disableTypography

          primary={

            <span className={classes.contactNameWrapper}>

              <ListItemSecondaryAction>
                {ticket.lastMessage && (
                  <>

                    <Typography
                      className={classes.lastMessageTime}
                      component="div"
                      variant="body2"
                      color="primary"
                      display="inline"
                    >

                      {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                        <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                      ) : (
                        <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                      )}
                    </Typography>

                  </>
                )}

              </ListItemSecondaryAction>

              <Typography
                noWrap
                component="div"
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >

                {ticket.contact.name}

                {profile === "admin" && (
                  <Tooltip title="Espiar conversaciones">
                    <VisibilityIcon
                      onClick={() => setOpenTicketMessageDialog(true)}
                      fontSize="small"
                      style={{
                        color: grey[700],
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginBottom: "5px",
                      }}
                    />
                  </Tooltip>
                )}

              </Typography>

              <ListItemSecondaryAction>
                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
              </ListItemSecondaryAction>
            </span>

          }
          secondary={
            <span
              className={classes.contactNameWrapper}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1, // Número de líneas que deseas mostrar antes de truncar
              }}
            >

              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              // style={{ display: "flex", alignItems: "center" }}
              >

                <span style={{ gridTemplateColumns: "1fr", width: "50%" }}>
                  {ticket.lastMessage.includes('data:image/png;base64') ? <MarkdownWrapper> Ubicación:</MarkdownWrapper> : <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>}
                </span>

                <span style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "2px", width: "50%", marginBottom: "5px" }}>

                  {ticket?.whatsapp?.name ? <Badge className={classes.connectionTag}>{ticket?.whatsapp?.name?.toUpperCase()}</Badge> : <br />}

                  {ticketUser ? <Badge style={{ backgroundColor: ticket.queue?.color || "#7c7c7c" }} className={classes.connectionTag}>{ticketUser}</Badge> : <br />}

                  <Badge style={{ backgroundColor: ticket.queue?.color || "#7c7c7c" }} className={classes.connectionTag}>{ticket.queue?.name?.toUpperCase() || "SIN ARCHIVOS"}</Badge>

                  {tag?.map((tag) => {
                    return (
                      <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
                    );
                  })}

                  <Badge
                    className={classes.newMessagesCount}
                    badgeContent={ticket.unreadMessages}
                    classes={{
                      badge: classes.badgeStyle,
                    }}
                  />

                </span>

                <span style={{ display: "grid", gap: "5px", alignItems: "justify-end", gridTemplateColumns: "1fr 1fr" }}>
                  {/* BOTON FINALIZAR  */}
                  {(ticket.status !== "closed") && (
                    <ButtonWithSpinner
                      //color="primary"
                      style={{ backgroundColor: 'LightGray', color: 'LightSlateGray' }}

                      // className={classes.acceptButton}
                      size="small"
                      loading={loading}
                      onClick={e => handleCloseTicket(ticket.id)}

                    >
                      {i18n.t("ticketsList.buttons.closed")}
                    </ButtonWithSpinner>
                  )}

                  {(ticket.status === "closed") && (
                    <ButtonWithSpinner
                      //color="primary"
                      style={{ backgroundColor: 'LightGray', color: 'LightSlateGray' }}

                      // className={classes.acceptButton}
                      size="small"
                      loading={loading}
                      onClick={e => handleReopenTicket(ticket.id)}
                    >
                      {i18n.t("ticketsList.buttons.reopen")}
                    </ButtonWithSpinner>
                  )}

                  {/* BOTON ACEPTAR  */}
                  {ticket.status === "pending" && (
                    <ButtonWithSpinner
                      //color="primary"
                      style={{ backgroundColor: ticket.queue?.color || "#5ac2d2", color: 'rgba(255, 255, 255, 0.68)' }}

                      // className={classes.acceptButton}
                      size="small"
                      loading={loading}

                      //PLW DESIGN INSERIDO O handleChangeTab
                      onClick={e => handleAcepptTicket(ticket.id)}
                    >
                      {i18n.t("ticketsList.buttons.accept")}
                    </ButtonWithSpinner>
                  )}
                </span>

              </Typography>

            </span>
          }

        />

      </ListItem>

      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;
