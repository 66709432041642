import React, { useEffect, useState } from 'react';
import toastError from "../../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../../services/api";  // Asumiendo que 'api' está configurado correctamente para incluir credenciales donde sea necesario

const PayPalButton = ({ total, onSuccessfulPayment }) => {
  const [isSdkReady, setIsSdkReady] = useState(false);

  useEffect(() => {
    async function loadPayPalScript() {
      try {
        // Solicitar el client-id de la API
        const response = await api.get('/paypal');  // Utiliza GET y asume que api está configurado para manejar base URL y autenticación
        const clientId = response.data.clientId;
    

        if (window.paypal) {
          console.log("PayPal SDK already loaded.");
          setIsSdkReady(true);
        } else if (clientId) {
          const script = document.createElement('script');
          script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons`;
          script.onload = () => {
            setIsSdkReady(true);
            console.log("PayPal SDK loaded successfully.");
          };
          document.body.appendChild(script);
        }
      } catch (error) {
        console.error("Failed to load PayPal SDK: ", error);
        toast.error("Failed to load payment options. Please refresh the page.");
      }
    }

    loadPayPalScript();
  }, []);

  useEffect(() => {
    if (isSdkReady && window.paypal) {
      window.paypal.Buttons({
        style: {
          layout: 'vertical',
          color: 'blue',
          shape: 'rect',
          label: 'paypal'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: total
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(details => {
            console.log('Payment Approved: ', details);
            onSuccessfulPayment(details);
          });
        },
        onError: (err) => {
          toast.error('Payment Error: ' + err);
        }
      }).render('#paypal-button-container');
    }
  }, [isSdkReady, total, onSuccessfulPayment]);

  return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
