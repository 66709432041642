import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ButtonWithSpinner from "../../components/ButtonWithSpinner"; // Importa el componente ButtonWithSpinner
import LoadingOverlay from "../../components/LoadingOverlay";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        flex: 1,
        padding: theme.spacing(2),
        border: "1px solid #e0e0e0",
    },
    inputField: {
        marginBottom: theme.spacing(1), // Reduce el margen entre campos
        
    },
    rightAlignButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const ZDGHistorico = () => {
    const classes = useStyles();
    const location = useLocation();
    const [inputs, setInputs] = useState({});
    const [searchParam, setSearchParam] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false); // Estado de carga para el botón
    const [progress, setProgress] = useState(0);

    const simulateProgress = () => {
        setProgress(0);
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    setLoading(false);
                    return 100;
                }
                return prevProgress + 10; // Ajusta este valor para controlar la velocidad del progreso
            });
        }, 500); // Ajusta el intervalo de tiempo según sea necesario
    };

    useEffect(() => {
        if (progress < 100) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [progress]);

    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true);
            try {
                const { data } = await api.post("/findWhatsapp/");
                setOptions(data);
            } catch (err) {
                toastError(err);
            }
            setLoading(false);
        };

        if (searchParam) {
            fetchContacts();
        }
    }, [searchParam]);

    const handleSelectOption = (event, newValue) => {
        setSelectedContact(newValue);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedContact && inputs.limite) {
            setButtonLoading(true); // Activar el estado de carga del botón
            simulateProgress(); // Iniciar la simulación del progreso
            try {
                const whatsappId = selectedContact.id;
                const limit = parseInt(inputs.limite, 10);
                console.log('whatsappId:', whatsappId, 'limit:', limit);

                const response = await api.post("/exportschats/", {
                    whatsappId,
                    limit,
                });

                if (response.data.success) {
                    toast.success('Mensajes importados exitosamente.');
                } else {
                    toast.error('Error al sincronizar los mensajes.');
                }
            } catch (error) {
                toastError(error);
            } finally {
                setButtonLoading(false); // Desactivar el estado de carga del botón
            }
        } else {
            alert('Por favor llena todos los campos.');
        }
    };

    return (
        <div className={classes.root}>
            <Container maxWidth="sm">
                <Paper className={classes.paper}>
                    <h1>Importar mensajes del Contacto</h1>
                    <Typography className={classes.note} variant="body2">
                        Nota: Asegúrese de escanear el código QR de WhatsApp para que funcione el historial de mensajes correctamente.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            className={classes.inputField}
                            label="Límite de importación"
                            variant="outlined"
                            name="limite"
                            value={inputs.limite || ""}
                            onChange={handleChange}
                            required
                            fullWidth
                            margin="dense"
                            placeholder="Número de mensajes por conversación"
                        />
                        <Autocomplete
                            className={classes.inputField}
                            fullWidth
                            options={options}
                            getOptionLabel={(option) => option.name || ""}
                            value={selectedContact}
                            onChange={handleSelectOption}
                            inputValue={searchParam}
                            onInputChange={(event, newInputValue) => {
                                setSearchParam(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Buscar conexión"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading && <CircularProgress color="inherit" size={0} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <div className={classes.rightAlignButton}>
                            <ButtonWithSpinner
                                type="submit"
                                color="primary"
                                loading={buttonLoading}
                            >
                                Importar Mensajes
                            </ButtonWithSpinner>
                        </div>
                    </form>
                </Paper>
                <LoadingOverlay open={progress > 0 && progress < 100} progress={progress} />
            </Container>
        </div>
    );
};

export default ZDGHistorico;
