import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles(theme => ({
   on: {
       color: green[600],
       fontSize: '20px'
   },
   off: {
       color: red[600],
       fontSize: '20px'
   },
   pointer: {
       cursor: "pointer"
   }
}));

export function RatingBox({ rating }) {
   const ratingTrunc = rating === null ? 0 : Math.trunc(rating);
   return <Rating
       defaultValue={ratingTrunc}
       max={3}
       readOnly
   />
}

export default function TableAttendantsStatus(props) {
   const { loading, attendants } = props;
   const classes = useStyles();
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(6);

   const handleChangePage = (event, newPage) => {
       setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
       setRowsPerPage(parseInt(event.target.value, 10));
       setPage(0);
   };

   function renderList() {
       return (rowsPerPage > 0
           ? attendants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
           : attendants
       ).map((a, k) => (
           <TableRow key={k}>
               <TableCell>{a.name}</TableCell>
               <TableCell align="center" title="1 - Insatisfechos, 2 - Satisfecho, 3 - Muy satisfecho" className={classes.pointer}>
                   <RatingBox rating={a.rating} />
               </TableCell>
               <TableCell align="center">{formatTime(a.avgSupportTime, 2)}</TableCell>
               <TableCell align="center">
                   {a.online ?
                       <CheckCircleIcon className={classes.on} />
                       : <ErrorIcon className={classes.off} />
                   }
               </TableCell>
           </TableRow>
       ))
   }

   function formatTime(minutes) {
       return moment().startOf('day').add(minutes, 'minutes').format('HH[h] mm[m]');
   }

   return (!loading ?
       <TableContainer component={Paper}>
           <Table size="small">
               <TableHead>
                   <TableRow selected>
                       <TableCell>Nombre</TableCell>
                       <TableCell align="center">Reseñas</TableCell>
                       <TableCell align="center">Tiempo de Servicio técnico</TableCell>
                       <TableCell align="right">Status (Actual)</TableCell>
                   </TableRow>
               </TableHead>
               <TableBody>
                   {renderList()}
               </TableBody>
           </Table>

           <TablePagination
               rowsPerPageOptions={[6, 10, 20]}
               component="div"
               count={attendants.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onChangeRowsPerPage={handleChangeRowsPerPage}
           />
       </TableContainer>
       : <Skeleton variant="rect" height={150} />
   )
}