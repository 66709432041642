import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const DateFilter = ({ onFiltered }) => {
  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

  // Efecto que se ejecuta una sola vez cuando el componente se monta
  useEffect(() => {
    // Notificar al componente padre sobre las fechas iniciales
    onFiltered(dateFrom, dateTo);
  }, []); // Las dependencias vacías aseguran que esto se ejecute solo una vez al montar

  const handleDateFromChange = (event) => {
    const newDateFrom = event.target.value;
    setDateFrom(newDateFrom);
    onFiltered(newDateFrom, dateTo);
  };

  const handleDateToChange = (event) => {
    const newDateTo = event.target.value;
    setDateTo(newDateTo);
    onFiltered(dateFrom, newDateTo);
  };


  return (
    <Grid spacing={2} container style={{ margin: 3 }}>
      <Grid xs={6} item>
        <TextField
          label="Filtrar Desde"
          type="date"
          variant="outlined"
          value={dateFrom}
          onChange={handleDateFromChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          label="Filtrar Hasta"
          type="date"
          variant="outlined"
          value={dateTo}
          onChange={handleDateToChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DateFilter;