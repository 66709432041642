import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { plan } = formValues;

  const newPlan = JSON.parse(plan);
  console.log(newPlan);
  //const { users, connections, price } = newPlan;
  return (
    <Grid item xs={12} sm={12}>
    <Typography variant="h6" gutterBottom className={classes.title}>
      Detalles del plan: {newPlan.title}
    </Typography>
    <Typography gutterBottom>Usuarios: {newPlan.description[0]}</Typography>
    <Typography gutterBottom>Whatsapps: {newPlan.description[1]}</Typography>
    <Typography gutterBottom>Colas: {newPlan.description[2]}</Typography>
    <Typography gutterBottom>Facturación: mensual</Typography>
    <Typography gutterBottom>Total: ${newPlan.price.toLocaleString('es-ec', {minimumFractionDigits: 2})}</Typography>
  </Grid>
  );
}

export default PaymentDetails;
