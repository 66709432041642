import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Button } from "@material-ui/core";

// Define el estilo directamente usando makeStyles
const useStyles = makeStyles((theme) => ({
  invoiceBox: {
    maxWidth: 800,
    margin: "auto",
    padding: 30,
    border: "1px solid #eee",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
    fontSize: 16,
    lineHeight: "24px",
    color: "#555",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  header: {
    color: "#333",
    fontSize: 24,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  amountDue: {
    fontSize: 48,
    fontWeight: "bold",
    color: "#333",
    textAlign: "center",
    marginBottom: 30,
  },
  detailsTable: {
    width: "100%",
    marginTop: 20,
    borderCollapse: "collapse",
  },
  detailsTh: {
    paddingTop: 20,
    textAlign: "left",
    borderBottom: "1px solid #eee",
  },
  detailsTd: {
    paddingTop: 20,
    borderBottom: "1px solid #eee",
  },
  summary: {
    textAlign: "right",
    marginTop: 30,
  },
  summaryItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
  },
  downloadButton: {
    marginTop: 30,
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#333",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
}));



const BodyInvoice = ({ invoiceID }) => {
  const classes = useStyles();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubscriptionDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/subInvoice', { invoiceID });
      setSubscriptionDetails(data);
    } catch (err) {
      toastError('Failed to fetch subscription details');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await api.post('/downals', { invoiceID }, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toastError('Failed to download PDF');
      console.error(err);
    }
  };

  useEffect(() => {
    if (invoiceID) {
      fetchSubscriptionDetails();
    }
  }, [invoiceID]);

  return (
    <div className={classes.invoiceBox}>
       <div className={classes.header}>Factura</div>
      <div className={classes.headerContainer}>
       
        <div className={classes.amountDue}>
          {subscriptionDetails ? `$${subscriptionDetails.price || '0.00'}` : '$0.00'}
        </div>
      </div>
      <table className={classes.detailsTable}>
        <tbody>
          <tr className="details">
            <th className={classes.detailsTh}>ID de la Factura de PayPal:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.invoicePaypalId || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Empresa:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.companyName || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Nombre del Cliente:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.clientName || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Email del Cliente:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.clientEmail || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Dirección de Envío:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.deliveryAddress || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Detalles del Plan:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.planName || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Estado del Pago:</th>
            <td className={classes.detailsTd}>{subscriptionDetails?.paymentStatus || 'N/A'}</td>
          </tr>
          <tr className="details">
            <th className={classes.detailsTh}>Descripción del Pago:</th>
            <td className={classes.detailsTd}>Pagado por PayPal</td>
          </tr>
        </tbody>
      </table>
      <div className={classes.summary}>
        <div className={classes.summaryItem}>
          <span>Subtotal</span>
          <span>{subscriptionDetails ? `$${subscriptionDetails.price || '0.00'}` : '$0.00'}</span>
        </div>
        <div className={classes.summaryItem}>
          <span>Total</span>
          <span>{subscriptionDetails ? `$${subscriptionDetails.price || '0.00'}` : '$0.00'}</span>
        </div>
      </div>
      <Button className={classes.downloadButton} onClick={handleDownload}>
        Descargar PDF
      </Button>
    </div>
  );
};

export default BodyInvoice;
