export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nombres*',
      requiredErrorMsg: 'Nombres es obligatorio'
    },
    lastName: {
      name: 'lastName',
      label: 'Apellidos*',
      requiredErrorMsg: 'Nombres es obligatorio'
    },
    address1: {
      name: 'address2',
      label: 'Dirección*',
      requiredErrorMsg: 'La dirección es obligatoria'
    },

    city: {
      name: 'city',
      label: 'Ciudad*',
      requiredErrorMsg: 'La ciudad es indispensable'
    },
    state: {
      name: 'state',
      label: 'Estado*',
      requiredErrorMsg: 'La ciudad es indispensable'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Código postal*',
      requiredErrorMsg: 'El código postal es obligatorio',
      invalidErrorMsg: 'Formato de código postal no válido'
    },
    country: {
      name: 'country',
      label: 'País*',
      requiredErrorMsg: 'El país es obligatorio'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Use this address for payment details'
    },
    invoiceId: {
      name: 'invoiceId',
      label: 'Use this invoiceId'
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: 'Nombre en la tarjeta*',
      requiredErrorMsg: 'El nombre en la tarjeta es obligatorio'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Card number*',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 987654321987654321)'
    },
    expiryDate: {
      name: 'expiryDate',
      label: 'Expiry date*',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: 'CVV is required',
      invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    }
  }
};
