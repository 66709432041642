import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from "@material-ui/core/Paper";

import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // margin: '2rem'
  },
  inputContainer: {
    // display: 'flex',
    // width: '100%',
    // marginBottom: '1rem'
  },
  input: {
    // flexGrow: 1,
    // marginRight: '1rem'
  },
  listContainer: {
    // width: '100%',
    // height: '100%',
    // marginTop: '1rem',
    // backgroundColor: '#f5f5f5',
    // borderRadius: '5px',
  },
  list: {
    // marginBottom: '5px'
  }
});

const ToDoList = () => {
  const classes = useStyles();

  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      // Impede que o usuário crie uma tarefa sem texto
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      // Editar tarefa existente
      const newTasks = [...tasks];
      newTasks[editIndex] = { text: task, updatedAt: now, createdAt: newTasks[editIndex].createdAt };
      setTasks(newTasks);
      setTask('');
      setEditIndex(-1);
    } else {
      // Adicionar nova tarefa
      setTasks([...tasks, { text: task, createdAt: now, updatedAt: now }]);
      setTask('');
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setEditIndex(index);
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  return (
    <>
      <Paper
        variant="outlined"
        // elevation={0}
        style={{
          border: "1px solid #e0e0e0",
          marginLeft: "1.5rem",
          marginRight: "1.5rem",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          padding: "1rem"
        }}
      >
        {/* <div className={classes.root}> */}

        {/* <div className={classes.inputContainer}> */}

        <Grid spacing={2} backgroundColor="white" container>
          <Grid xs={12} md={10} item>
            <TextField
              // className={classes.input}
              fullWidth
              label="Nueva Tarea"
              value={task}
              onChange={handleTaskChange}
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid xs={12} md={2} item>
            <Button
              fullWidth
              color="primary"
              variant='contained'
              disableElevation
              onClick={handleAddTask}

            >
              {editIndex >= 0 ? 'Guardar' : 'Agregar'}
            </Button>
            {/* </div> */}
            {/* <div className={classes.listContainer}> */}
          </Grid>
        </Grid>

        <List className='grid gap-3'>
          {tasks.map((task, index) => (
            // <ListItem key={index} className={classes.list}>
            <ListItem key={index} className='border border-gray-200 rounded-lg lg:w-5/6'>

              <div className='m-0'>
                <ListItemText className='uppercase font-bold' primary={task.text} />
                <ListItemText className='text-sm border bg-gray-100 py-1 px-3 rounded text-gray-500' secondary={task.updatedAt.toLocaleString()} />
              </div>

              <ListItemSecondaryAction>
                <IconButton onClick={() => handleEditTask(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteTask(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

      </Paper>


    </>
  );
};


export default ToDoList;
