import React, { useContext, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";

import PayPalButton from "./Forms/PayPalButton";
import PaymentForm from "./Forms/PaymentForm";
import ReviewOrder from "./ReviewOrder";
import CheckoutSuccess from "./CheckoutSuccess";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";


import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import useStyles from "./styles";
import Invoices from "../../pages/Financeiro";


export default function CheckoutPage(props) {
  const steps = ["Plan", "Revisar", "Pago con PayPal"];

  const { formId, formField } = checkoutFormModel;



  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [datePayment, setDatePayment] = useState(null);
  const [invoiceId, setinvoiceId] = useState(props.Invoice.id);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { user } = useContext(AuthContext);

  function _renderStepContent(step, setFieldValue, setActiveStep, values, actions) {
    switch (step) {
      case 0:
        return <PaymentForm
          formField={formField}
          setFieldValue={setFieldValue}
          setActiveStep={setActiveStep}
          activeStep={step}
          invoiceId={invoiceId}
          values={values}
        />;

      case 1:
        return <ReviewOrder  />;
      case 2:
        let plan = null;
        try {
          plan = values.plan ? JSON.parse(values.plan) : null;
          console.log(plan)
          console.log(plan.price)
        } catch (error) {
          console.error("Error parsing plan JSON:", error);
          plan = null;
        }
        const totalPrice = plan ? plan.price : '0.00';
        return (
          <PayPalButton
            total={totalPrice}
            onSuccessfulPayment={(details) => _submitForm(values, details, actions)} // Pasar _submitForm como callback
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }



  async function _submitForm(values, details, actions) {
    try {
      const plan = JSON.parse(values.plan);
      const newValues = {
        //firstName: values.firstName,
        //lastName: values.lastName,
        //address2: values.address2,
        //city: values.city,
        //state: values.state,
        // zipcode: values.zipcode,
        //country: values.country,
        //useAddressForPaymentDetails: values.useAddressForPaymentDetails,
        // nameOnCard: values.nameOnCard,
        // cardNumber: values.cardNumber,
        //cvv: values.cvv,
        isActive: true,
        clientName: `${details.payer.name.given_name} ${details.payer.name.surname}`,
        clientEmail: details.payer.email_address,
        deliveryAddress: `${details.purchase_units[0].shipping.address.address_line_1}, ${details.purchase_units[0].shipping.address.admin_area_2}, ${details.purchase_units[0].shipping.address.admin_area_1}, ${details.purchase_units[0].shipping.address.postal_code}, ${details.purchase_units[0].shipping.address.country_code}`, // Ajusta según la estructura de tu dirección
        price: details.purchase_units[0].amount.value,
        captureId: details.purchase_units[0].payments.captures[0].id,
       // description: 'Descripción del producto o servicio', // Esto deberías definirlo o extraerlo de alguna parte
        paymentStatus: details.status,
        transactionStatus: details.status, // Asumiendo que el status de la transacción es el mismo que el del pago
        payerId: details.payer.payer_id,
        merchantId: details.purchase_units[0].payee.merchant_id,
        merchantEmail: details.purchase_units[0].payee.email_address,
        transaccionId: details.purchase_units[0].payments.captures[0].id,
        invoicePaypalId: details.id,
        captureId: details.purchase_units[0].payments.captures[0].id,
        plan: values.plan,
        id: invoiceId,
        //price: plan.price,
       // users: plan.description.find(desc => desc.includes("users")).split(" ")[0], // Extract the number of users
      //  connections: plan.description.find(desc => desc.includes("connections")).split(" ")[0], // Extract the number of connections
   
      }
      //console.log("json plan antes de enviar:", plan);
      //console.log("newValues antes de enviar:", newValues);

      const { data } = await api.post("/subscription", newValues);
      setDatePayment(data);
      console.log(data)
      if (actions) {
        actions.setSubmitting(false);
      }
      // Cierra el modal aquí
      props.onClose();
      toast.success("Suscripción realizada con éxito");

        // Retrasa el envío del correo para que el toast pueda mostrarse primero
        setTimeout(async () => {
          console.log(data.invoice.id)
          if (data && data.invoice && data.invoice.id) {
            const emailData = {
                invoiceID: data.invoice.id  // Usar el id de la factura obtenido de la respuesta del backend
            };
              const emailResponse = await api.post("/SendEmail", emailData);
              console.log("Respuesta del envío de correo:", emailResponse);
          }
      }, 500);  // Ajusta el tiempo de espera según lo necesario

    } catch (err) {
      if (actions) {
        actions.setSubmitting(false);
      }
      toastError(err);
      console.error("Error submitting form: ", err.response || err);
    }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }


  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Falta poco!
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess pix={datePayment} />
        ) : (
          <Formik
            initialValues={{
              ...user,
              ...formInitialValues
            }}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values, actions }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep, setFieldValue, setActiveStep, values, actions)}

                <div className={classes.buttons}>
                  {activeStep > 0 && (
                    <Button onClick={_handleBack} className={classes.button} disableElevation>
                      Volver
                    </Button>
                  )}
                  {activeStep > 0 && activeStep < steps.length - 1 && (
                    <div className={classes.wrapper}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        disableElevation
                      >
                        PRÓXIMO
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );


}
